import React from 'react'
import ImageInPage from '../wal-common/src/components/Image/ImageInPage/ImageInPage'

import Layout from '../components/Layout/Layout'

const LeistungenUndPreise = () => {
  return (
    <Layout cols={true} title="Leistungen und Preise">

      <ImageInPage file={'MVA_2873.jpg'} />

      <p className="is-clearfix">
        Ein Massagegutschein eignet sich perfekt als persönliches Geschenk oder
        kleine Aufmerksamkeit für jeden Anlass. Termine – nach Vereinbarung.
      </p>

        <table className="table is-hoverable is-fullwidth">
          <thead className="has-background-primary has-text-white">
            <tr>
              <th />
              <th>30 min</th>
              <th>1 Std</th>
              <th>2 Std</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Meditative Entspannungsmassage</td>
              <td />
              <td>50 EUR</td>
              <td>90 EUR</td>
            </tr>
            <tr>
              <td>Thai-Yoga-Massage</td>
              <td>30 EUR (Rücken)</td>
              <td>50 EUR</td>
              <td />
            </tr>
            <tr>
              <td>Klangschalenmassage</td>
              <td />
              <td>50 EUR</td>
              <td />
            </tr>
            <tr>
              <td>V.I.P. Pinselgesichtsmassage</td>
              <td />
              <td>50 EUR</td>
              <td />
            </tr>
            <tr>
              <td>Mnushi-Mittwoch-Teilnahme</td>
              <td />
              <td />
              <td>10 EUR</td>
            </tr>
            <tr>
              <td>
                Entspannungs-Training mit Grundschulkinder (max 6 Teilnehmer)
              </td>
              <td />
              <td>60 EUR</td>
              <td />
            </tr>
          </tbody>
        </table>
    </Layout>
  )
}

export default LeistungenUndPreise
